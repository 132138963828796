import React from "react";
import Header from "../../components/header";
import Hero from "../../components/hero";
import Layout from "../../components/layout";
import WithProviders from "../../components/WithProviders";

import "./style.css";
const Services = (props) => {
  const { language, updateLanguage, direction, location } = props;
  return (
    <Layout
      direction={direction}
      showFooterForm
      language={language}
      location={location}
    >
      <div>
        <Header
          current="service"
          language={language}
          updateLanguage={updateLanguage}
          direction={direction}
        />
        <Hero scrollTo="#smart-store" />
      </div>
    </Layout>
  );
};

export default WithProviders(Services);
